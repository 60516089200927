<template>
  <div class="product__action-container">
    <h1>{{ $t("FabricSelector:Header") }}</h1>
    <div
      v-for="filter in filters"
      :key="filter.id"
      class="checkbox__container product__checkbox-container"
    >
      <span class="checkbox__header">{{ filter.name }}</span>
      <ul class="checkbox__list">
        <li v-for="value in filter.values" :key="value.code">
          <label class="checkbox__input-container">
            <input
              type="checkbox"
              :value="value.code"
              :checked="isActiveFilterOption(value.code)"
              @change="onFilterChange"
            />
            {{ value.name }}
            <span class="checkbox__checkmark"></span>
          </label>
        </li>
      </ul>
    </div>
    <div class="product__search-container">
      <input
        v-model.lazy.trim="searchText"
        :placeholder="$t('Products:Search')"
        @keypress.enter.prevent
        @keyup.enter.prevent="$event.target.blur()"
        class="input__search"
      />
    </div>
  </div>
  <div class="product__action-container-2">
    <div class="product__dropdown-container">
      <label for="product-selector">{{ selectedProduct?.name ?? "Välj tyg" }}</label>
      <select class="product__dropdown" name="product-selector" v-model="selectedProduct">
        <option
          v-for="product in products"
          :key="product.productId"
          :value="product"
        >{{ product.name }}</option>
      </select>
    </div>
  </div>
  <VariantSelector
    v-if="selectedProduct"
    :product="selectedProduct"
    :order-line="orderLine"
    @select-variant="variantSelectorSelectVariant"
  />
</template>

<script src="./ProductSelector.ts" lang="ts"></script>

<style lang="scss" scoped>
@import "./ProductSelector.scss";
</style>
